import React from 'react';

/**
 * Retrives the search key change.
 * 
 * @param {String} search Search value Key.
 * @param  onSearchChange Function callback on search key change. 
 * @returns 
 */
const SearchInput = ({ search, onSearchChange , total}) => {

    return (
        <form className='mt-1' >
            <label htmlFor="search-input" className="mb-2  text-sm font-medium text-gray-900 sr-only dark:text-white">Recherche</label>
            <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                </div>
                <input onChange={onSearchChange} type="search" id="search-input" className="block w-full p-2 mt-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-500 focus:border-gray-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" placeholder="Nom de la chaine" required />
                <button className="text-white absolute right-2.5 bottom-1 bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-2 py-1 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">{total}</button>
            </div>
        </form>
    );
}

export default SearchInput;
