import React from 'react';
const ChannelFilter = ({item, onSelectItem}) => {
    return (
      <li key={item.id} className='focus:cursor-auto focus:ring-4 focus:outline-none focus:ring-gray-700 px-4 py-2 bg-gray-500 rounded-full text-white cursor-grabbing mb-2 hover:bg-gray-700 hover:text-white ' onClick={()=>onSelectItem(item.id)}>
          {item.name}
        </li>
    );
}

export default ChannelFilter;
