import { configureStore } from '@reduxjs/toolkit';
import visibleChannelsReducer from './slice/channels/visibleChannelsSlice';
import likedChannelsReducer from './slice/channels/likedChannelsSlice';
import userReducer from './slice/user/userSlice';
import allChannelsReducer from './slice/channels/allChannelsSlice';
import currentChannelReducer from './slice/channels/currentChannelSlice'; // Import du slice currentChannel
import channelsCategorieReducer from './slice/channels/channelsCategoriesSlice';
import helperReducer from './slice/helpers/helperSlice';

const store = configureStore({
    reducer: {
        visibleChannels: visibleChannelsReducer,
        likedChannels: likedChannelsReducer,
        user: userReducer,
        allChannels: allChannelsReducer,
        currentChannel: currentChannelReducer,
        channelsCategories: channelsCategorieReducer,
        helper: helperReducer
    },
});

export default store;
