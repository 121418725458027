import { createSlice } from '@reduxjs/toolkit';

const likedChannelsSlice = createSlice({
    name: 'likedChannels',
    initialState: {
        liked: [],
    },
    reducers: {
        addLikedChannel: (state, action) => {
            state.liked.push(action.payload);
            localStorage.setItem('liked', JSON.stringify(state.liked));
        },
        refreshByStorage: (state, action) => {
            state.liked = action.payload;
        },
        updateLikedChannel: (state, action) => {
            const { id, newData } = action.payload;
            const index = state.liked.findIndex(channel => channel.id === id);
            if (index !== -1) {
                state.liked[index] = { ...state.liked[index], ...newData };
            }
        },
        removeLikedChannel: (state, action) => {
            const index = state.liked.findIndex(channel => channel.id === action.payload);
            if (index !== -1) {
                state.liked.splice(index, 1);
                localStorage.setItem('liked', JSON.stringify(state.liked));

            }
        },
    },
});

export const { addLikedChannel, refreshByStorage, updateLikedChannel, removeLikedChannel } = likedChannelsSlice.actions;
export default likedChannelsSlice.reducer;
