import { createSlice } from '@reduxjs/toolkit';

const helperSlice = createSlice({
    name: 'helper',
    initialState: {
        isLoading: true,
        userNavigation: [
            { name: 'Your Profile', href: '#' },
            { name: 'Settings', href: '#' },
            { name: 'Sign out', href: '#' },
        ]// Valeur initiale du canal actuel
    },
    reducers: {
        setHelperIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});

export const { setHelperIsLoading } = helperSlice.actions;
export default helperSlice.reducer;
