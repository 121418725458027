import React, { Fragment, useEffect, useState, CSSProperties } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, HeartIcon, } from '@heroicons/react/24/outline'
import VideoJS from './components/helpers/VideoJs'
import Modal from './components/helpers/Modal'
import { DataDisplayer } from './components/helpers/Data-displayer'
import Header from './components/menu/Header'
import Footer from './components/footer/footer'
import { useChannelContext } from './sevices/hooks/ChannelContext';
import ClipLoader from "react-spinners/ClipLoader";
import Live from './components/helpers/Live';
import ButtonDuo from './components/buttons/Button'
import ChannelFilters from './components/channels/ChannelsFilters'
import DraggableTabs from './components/helpers/DraggableTabs'
import { ActionToast } from './components/helpers/Toast'
import { useDispatch } from 'react-redux'
import { loadAllData } from './sevices/api/dataLoader'
import { useSelector } from 'react-redux';
import { updateSearch, updateVisibleChannelByCategory, updateVisibleChannelBySearch } from './sevices/store/slice/channels/visibleChannelsSlice'

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#1F2937",
};
const navigation = {
  channel: { name: 'My channel', href: '#', current: true }
}



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Home() {
  const dispatch = useDispatch();

  const [isSearching, setIsSearching] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [showToast, setShowToast] = useState(true);
  const channelsCategories = useSelector(state => state.channelsCategories.categories);
  const currentChannel = useSelector(state => state.currentChannel.channel);
  const isLoading = useSelector(state => state.helper.isLoading);
  const userNavigation = useSelector(state => state.helper.userNavigation);
  const user = useSelector(state => state.user.userData);
  const allChannels = useSelector(state => state.allChannels.channels);
  const visibleChannels = useSelector(state => state.visibleChannels.visiblechannels);
  const search = useSelector(state => state.visibleChannels.search);
  const likedChannels = useSelector(state => state.likedChannels.liked);
  const [openLiked, setOpenLiked] = useState(false);
  const [openChannelList, setOpenChannelList] = useState(false);

  /**
   * Chargement des données de l'application
   */
  useEffect(() => {
    // Charge toutes les données au montage du composant
    dispatch(loadAllData());

  }, [dispatch]);

  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here,
    player.on('waiting', () => {
    });

    player.on('dispose', () => {
    });
  };

  const updatVisibleChannelByCategory = (categorie) => {
    if (categorie) {
      if (!search) {
        const searchedChannels = allChannels.filter(channel => channel.categories.includes(categorie.toLowerCase()))
        dispatch(updateVisibleChannelByCategory(searchedChannels));
      } else {
        const searchedChannels = allChannels.filter(channel => channel.name.toLowerCase().includes(search.toLowerCase()))
        searchedChannels = searchedChannels.filter(channel => channel.categories.includes(categorie.toLowerCase()))

        dispatch(updateVisibleChannelByCategory(searchedChannels));
      }
    } else dispatch(updateVisibleChannelByCategory(allChannels));
  }


  /**
   * Set on search channel event.
   * 
   * @param {event} e On change value event target.
   */
  function onSearchChannelChange(e) {
    dispatch(updateSearch(e.target.value));
    if (e.target.value) {
      const searchedChannels = allChannels.filter(channel => channel.name.toLowerCase().includes(e.target.value.toLowerCase()))
      dispatch(updateVisibleChannelBySearch(searchedChannels));
    } else dispatch(updateVisibleChannelBySearch(allChannels));
  }

  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10"
                        src="./logo.png"
                        alt="Kubaniptv"
                      />

                    </div>
                    <div className=" md:block">
                      <div className="ml-1 md:ml-10 flex items-baseline space-x-4 ">
                        <div className="md:mx-auto md:max-w-7xl px-4 py-6 sm:px-6 lg:px-8 flex">
                          <ButtonDuo title={'Categories'} onClickFunction={() => setShowCategories(!showCategories)} />
                          <Live title={currentChannel.name} />
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="block">
                    <div className="ml-4 flex items-center md:ml-6">

                      {/*  //Show and hide channels list */}
                      <button
                        type="button"
                        className="md:hidden relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        onClick={() => setIsSearching(!isSearching)}
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View channels list</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                      </button>
                      <button
                        type="button"
                        className="hidden md:block relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        onClick={() => setOpenChannelList(!openChannelList)}
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View channels list</span>
                        {openChannelList ? (
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        ) : (
                          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        )}

                      </button>

                      <button
                        type="button"
                        className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        onClick={() => setOpenLiked(!openLiked)}
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View liked channels</span>
                        <HeartIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>
        <main>
          <ChannelFilters items={channelsCategories} visible={showCategories} onSelectItem={updatVisibleChannelByCategory} />
          {isLoading ? (
            <ClipLoader
              color='#ffffff'
              loading={isLoading}
              cssOverride={override}
              size={400}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <>
              <div className={openChannelList ? 'md:grid grid-cols-3 gap-4 mt-3' : "mt-3"}>

                <div class={openChannelList ? "px-2 hidden md:block ml-14 max-w-md bg-stone-50 shadow-2xl rounded-md" : "px-2 hidden ml-14 max-w-md bg-stone-50 shadow-2xl rounded-md"}>
                  <DataDisplayer visibleChannels={visibleChannels} onSearchChannelChange={onSearchChannelChange} />
                </div>
                <div className={openChannelList ? "shadow-2xl md:ml-16 md:col-span-2 md:max-w-3xl py-8 sm:px-6 lg:px-4 rounded-md" : "shadow-2xl items-center mx-auto md:col-span-2 md:max-w-3xl py-8 sm:px-6 lg:px-4 rounded-md"} >
                  <VideoJS options={currentChannel.option} onReady={handlePlayerReady} />
                  <Modal title='Mes chaines' open={isSearching} setOpen={setIsSearching} >
                    <DataDisplayer visibleChannels={visibleChannels} onSearchChannelChange={onSearchChannelChange} />
                  </Modal>
                </div>
              </div>
              <Modal title="Mes chaines favories" open={openLiked} setOpen={setOpenLiked} >
                <DataDisplayer visibleChannels={likedChannels} onSearchChannelChange={() => { }} />
              </Modal>
            </>
          )}

        </main>

        <Footer />
      </div>
    </>
  )
}
