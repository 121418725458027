import React from 'react';

/**
 * Composant d'alerte réutilisable qui affiche des messages avec différents styles en fonction du type d'alerte.
 *
 * @param {string} text - Le texte à afficher dans l'alerte.
 * @param {string} type - Le type d'alerte (success, danger, warning, etc.).
 * @returns {JSX.Element} Composant d'alerte avec le texte et le style appropriés.
 */
const Alert = ({ text, type }) => {
    let alertClasses = 'flex items-center p-4 mb-4 text-sm border rounded-lg';
    let textColor = 'text-gray-800';
    let bgColor = 'bg-gray-50';
    let borderColor = 'border-gray-300';

    switch (type) {
        case 'success':
            alertClasses += ' text-green-800 border-green-300 bg-green-50';
            textColor = 'dark:text-green-400';
            bgColor = 'dark:bg-gray-800';
            borderColor = 'dark:border-green-800';
            break;
        case 'danger':
            alertClasses += ' text-red-800 border-red-300 bg-red-50';
            textColor = 'dark:text-red-400';
            bgColor = 'dark:bg-gray-800';
            borderColor = 'dark:border-red-800';
            break;
        case 'warning':
            alertClasses += ' text-yellow-800 border-yellow-300 bg-yellow-50';
            textColor = 'dark:text-yellow-300';
            bgColor = 'dark:bg-gray-800';
            borderColor = 'dark:border-yellow-800';
            break;
        default:
            alertClasses += ' text-blue-800 border-blue-300 bg-blue-50';
            textColor = 'dark:text-blue-400';
            bgColor = 'dark:bg-gray-800';
            borderColor = 'dark:border-blue-800';
            break;
    }

    return (
        <div className={`${alertClasses} ${textColor} ${borderColor} ${bgColor} mt-3`} role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Alert</span>
            <div>
                <span className="font-medium text-center"></span> {text}
            </div>
        </div>
    );
};

export default Alert;
