import { createSlice } from '@reduxjs/toolkit';

const visibleChannelsSlice = createSlice({
    name: 'visibleChannels',
    initialState: {
        visiblechannels: [],
        search:'',
    },
    reducers: {
        addVisibleChannel: (state, action) => {
            state.visiblechannels = action.payload
        },
        updateSearch: (state, action) => {
            state.visiblechannels = action.payload
        },
        updateVisibleChannel: (state, action) => {
            const { id, newData } = action.payload;
            const index = state.visiblechannels.findIndex(channel => channel.id === id);
            if (index !== -1) {
                state.visiblechannels[index] = { ...state.visiblechannels[index], ...newData };
            }
        },
        updateVisibleChannelBySearch: (state, action) => {
            if (action.payload) {
                state.visiblechannels = action.payload 
            }
        },
        updateVisibleChannelByCategory: (state, action) => {
            if (action.payload) {
                state.visiblechannels = action.payload
            }
        },
        removeChannel: (state, action) => {
            const index = state.visiblechannels.findIndex(channel => channel.id === action.payload);
            if (index !== -1) {
                state.visiblechannels.splice(index, 1);
            }
        },
    },
});

export const { addVisibleChannel, updateSearch, updateVisibleChannel, removeChannel, updateVisibleChannelBySearch, updateVisibleChannelByCategory } = visibleChannelsSlice.actions;
export default visibleChannelsSlice.reducer;
