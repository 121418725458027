import React from 'react';
import { TvIcon } from '@heroicons/react/24/outline'

const DarkButton = ({ children, onClickButton, current }) => {


    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ')
    }
    return (
        <button type="button" onClick={onClickButton}
            className={classNames(
                current
                    ? 'bg-gray-900 text-white'
                    : ' border border-gray-700',
                'rounded-full w-8 h-8 font-medium text-sm p-1 text-center mr-2 mb-2 focus:ring-4 focus:outline-none focus:ring-gray-300'
            )}
        // className={"text-gray-700 hover:text-white border border-gray-700 hover:bg-gray-700   dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"}
        >
            {children}
        </button>
    );
}

export default DarkButton;
