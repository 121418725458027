import React, { createContext, useContext, useState } from 'react';

const ChannelContext = createContext();

export function ChannelProvider({ children }) {

    const [likedChannels, setLikedChannels] = useState([]);
    const [visibleChannels, setVisibleChannels] = useState([]);
    const [channelsCategories, setChannelsCategories] = useState([]);
    const [allChannelsData, setAllChannelsData] = useState([]);

    const [currentChannel, setCurrentChannel] = useState({
        id: null,
        name: "Rakuten Tv Show",
        option: {
            autoplay: true,
            controls: true,
            responsive: true,
            loop: true,
            fluid: true,
            poster: 'https://i.imgur.com/H08jcJi.png',
            sources: [{
                src: 'https://rakuten-tvshows-7-fr.plex.wurl.tv/playlist.m3u8',
                type: 'application/x-mpegURL'
            }
            ]
        }
    });


    // Fonction asynchrone pour récupérer les catégories des chaînes
    const fetchCategories = async () => {
        try {
            // Effectue une requête fetch vers le fichier local categories.json
            const response = await fetch('/json/categories.json');

            // Convertit la réponse en format JSON
            const data = await response.json();

            // Met à jour les données des catégories de chaînes avec les données récupérées
            setChannelsCategories(data); // Mettre à jour les données
        } catch (error) {
            // En cas d'erreur lors de la récupération des données, affiche l'erreur dans la console
            console.error('Erreur lors de la récupération des données : ', error);
        }
    };


    const updateCurrentChannel = (channel) => {
        setCurrentChannel(channel);
    };
    const updateAllChannels = (channels) => {
        setAllChannelsData(channels);
    };
    const updateVisibleChannels = (channels) => {
        setVisibleChannels(channels);
    };

    const addLikedChannel = (channel) => {
        setLikedChannels([...likedChannels, channel]);
    };


    const removeLikedChannel = (channel) => {
        let channels = likedChannels;
        channels = channels.filter((c) => c.id !== channel.id);
        setLikedChannels(channels);
    };
    const updatVisibleChannelByCategory = (category) => {
       let channels = allChannelsData.filter(channel => channel.categories.includes(category))

        updateVisibleChannels(channels);
    };

    return (
        <ChannelContext.Provider value={{
            currentChannel,
            updateCurrentChannel,
            likedChannels,
            addLikedChannel,
            removeLikedChannel,
            visibleChannels,
            updateVisibleChannels,
            channelsCategories,
            fetchCategories,
            updatVisibleChannelByCategory,
            allChannelsData,
            updateAllChannels
        }}>

            {children}
        </ChannelContext.Provider>
    );
}

export function useChannelContext() {
    return useContext(ChannelContext);
}
