import React from 'react';

/**
 * Retrive the header component with title.
 * 
 * @param {string} title The header title. 
 * @returns 
 */
const Header = ({ title }) => {
    return (
        <header className="bg-white shadow">
            <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 flex">
                {/* Add banner here */}
            </div>
        </header>
    );
}

export default Header;
