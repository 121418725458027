
import { useRef, useState } from "react";
import Channel from "../channels/Channel";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import SearchInput from "../forms/Search-input";
import { useSelector, useDispatch } from 'react-redux';
import { updateSearch, updateVisibleChannelBySearch } from "../../sevices/store/slice/channels/visibleChannelsSlice";
import Alert from "./Alert";

export function DataDisplayer({ onSearchChannelChange, visibleChannels }) {


    const [search, setSearch] = useState('');

    const cache = useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 60
        })
    );

    return (
        <>

            {visibleChannels.length ? (
                <>
                    <SearchInput search={search} onSearchChange={onSearchChannelChange} total={visibleChannels.length} />
                    <div className="mt-2 overflow-auto h-96 mt-4 scroll-p-px accent-current focus:scroll-auto scroll-me-1">
                        <AutoSizer>
                            {({ width, height }) => {
                                return (
                                    <ul role="list" className="divide-y divide-gray-100">
                                        <List
                                            className="divide-y divide-gray-100"
                                            width={width}
                                            height={height}
                                            rowHeight={cache.current.rowHeight}
                                            deferredMeasurementCache={cache.current}
                                            rowCount={visibleChannels.length}
                                            rowRenderer={({ key, index, style, parent }) => {
                                                const channel = visibleChannels[index];
                                                return (
                                                    <CellMeasurer
                                                        key={key}
                                                        cache={cache.current}
                                                        parent={parent}
                                                        columnIndex={0}
                                                        rowIndex={index}
                                                    >
                                                        <div key={channel.id} style={style}>
                                                            <Channel key={channel.id} channel={channel} style={{ height: { height } }} />
                                                        </div>
                                                    </CellMeasurer>
                                                );
                                            }}
                                        />
                                    </ul>
                                );

                            }}
                        </AutoSizer>
                    </div>
                </>

            ) : (
                <Alert text="Nous n'avons trouvé aucune chaine!" type={'warning'} />
            )}

        </>
    )
}