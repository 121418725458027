import { createSlice } from '@reduxjs/toolkit';

const currentChannelSlice = createSlice({
    name: 'currentChannel',
    initialState: {
        channel: JSON.parse(localStorage.getItem('currentChannel')) ? JSON.parse(localStorage.getItem('currentChannel')): {
            id: 0,
            name: "Rakuten Tv Comedy",
            option: {
                autoplay: true,
                controls: true,
                responsive: true,
                loop: true,
                fluid: true,
                poster: 'https://i.imgur.com/H08jcJi.png',
                sources: [{
                    src: 'https://rakuten-tvshows-7-fr.plex.wurl.tv/playlist.m3u8',
                    type: 'application/x-mpegURL'
                }
                ]
            }
        },
    },
    reducers: {
        setCurrentChannel: (state, action) => {
            state.channel = action.payload;
            localStorage.setItem('currentChannel', JSON.stringify(state.channel));
        },
    },
});

export const { setCurrentChannel } = currentChannelSlice.actions;
export default currentChannelSlice.reducer;
