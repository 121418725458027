import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        userData: {}, // Exemple de structure
    },
    reducers: {
        updateUser: (state, action) => {
            state.userData = { ...state.userData, ...action.payload };
        },
        clearUser: (state) => {
            state.userData = {};
        },
    },
});

export const { updateUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
