// Fonction asynchrone pour récupérer les catégories des chaînes
export const fetchCategories = async () => {
    try {
        let categories = [{ id: '', name: "Tout" }];

        // Effectue une requête fetch vers le fichier local categories.json
        const response = await fetch('/json/categories.json');

        // Convertit la réponse en format JSON
        let data = await response.json();
        data = data.filter(item => item.id !== "xxx");
        categories.push(...data);

        return categories
    } catch (error) {
        // En cas d'erreur lors de la récupération des données, affiche l'erreur dans la console
        console.error('Erreur lors de la récupération des données : ', error);
    }
};
