import React from 'react';

const Live = ({title}) => {
    return (
        <>
            <h1 className="text-sm md:text-2xl font-bold tracking-tight text-white ml-4 mt-2">{title}</h1>
            <div className="mt-1 p-2 flex items-center gap-x-1.5">
                <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                    <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                </div>
                <p className="text-xs leading-5 text-white">Live</p>
            </div>
        </>
    );
}

export default Live;
