import { createSlice } from '@reduxjs/toolkit';

const channelsCategoriesSlice = createSlice({
    name: 'channelsCategories',
    initialState: {
        categories: [] // Valeur initiale du canal actuel
    },
    reducers: {
        setChannelsCategories: (state, action) => {
            state.categories = action.payload;
        },
    },
});

export const { setChannelsCategories } = channelsCategoriesSlice.actions;
export default channelsCategoriesSlice.reducer;
