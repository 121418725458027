/**
 * Export all channels data
 */
export const allChannels = async () => {
    try {
        console.log('HERE');
        const response = await fetch('json/channels.json', {
            method: 'GET',
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();

        return allStream(data);
    } catch (error) {
        console.error('Error:', error);
    }
}

/**Retrives all streams data and mapping as a channel data */
const allStream = async (channels) => {
    try {
        const categorie_xxx = "xxx"
        const response = await fetch('json/streams.json', {
            method: 'GET',
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();


        if (channels) {
            let readychannel = [];
            channels.forEach(channel => {
                let str = '';
                if (data) {
                    str = data.filter(stream => channel.id === stream.channel)

                }
                //Check if stream exist or stream category diffrent to xxx
                if (str.length && channel.categories != categorie_xxx) {
                    readychannel.push({
                        id: channel.id,
                        name: channel.name,
                        logo: channel.logo,
                        subdivision: channel.subdivision,
                        country: channel.country,
                        categories: channel.categories,
                        url: str ? str[0].url : '',
                    })
                }
            });
            return readychannel;

        }
    } catch (error) {
        console.error('Error:', error);
    }
}
