import { addChannel } from '../store/slice/channels/allChannelsSlice';
import { setChannelsCategories } from '../store/slice/channels/channelsCategoriesSlice';
import { refreshByStorage } from '../store/slice/channels/likedChannelsSlice';
import { addVisibleChannel } from '../store/slice/channels/visibleChannelsSlice';
import { setHelperIsLoading } from '../store/slice/helpers/helperSlice';
import { updateUser } from '../store/slice/user/userSlice';
import { fetchCategories } from './fetchCategories';
import { allChannels } from './fetchChannels';

export const loadAllData = () => {
    return async (dispatch) => {
        // Charger les données de chaque slice
        await dispatch(addChannel(await allChannels()));
        await dispatch(addVisibleChannel(await allChannels()));
        await dispatch(setChannelsCategories(await fetchCategories()));
        
        let liked = JSON.parse(localStorage.getItem('liked'));
        if (liked) {
            await dispatch(refreshByStorage(liked));
        }
        

        await dispatch(updateUser({
            name: 'Joel Kubanza',
            email: 'kubanza.jo@gmail.com',
            imageUrl:
                '/user.jpg',
        }))
        await dispatch(setHelperIsLoading(false));

    };
};


