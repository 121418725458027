import React from 'react';
import { Dropdown } from 'flowbite-react';
import ChannelFilter from './channelFilter';

/**
 * Composant React permettant d'afficher le filtre des éléments.
 * 
 * @param {array} items Listes des items à filtrer.
 * @param {function} onSelectItem Function qui se déclanche lors de la sélection d'un item.
 * @param {bool} visible Check la visibilité du filtre.
 * @returns 
 */
const ChannelFilters = ({ items, onSelectItem, visible }) => {
  return (
    <div className='mb-4' >
      <ul className={visible ? 'flex flex-row gap-4 overflow-y-auto mx-8 my-4' : 'flex flex-row gap-4 overflow-y-auto mx-4 my-2 hidden'}>
        {items && (
          items.map((item) => <ChannelFilter item={item} onSelectItem={onSelectItem} />)
        )}
      </ul>
    </div>);
}

export default ChannelFilters;
