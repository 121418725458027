import React from 'react';
import DarkButton from '../buttons/Dark-button';
import { HeartIcon, PlayIcon } from '@heroicons/react/24/outline';
import { useChannelContext } from '../../sevices/hooks/ChannelContext';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentChannel } from '../../sevices/store/slice/channels/currentChannelSlice';
import { addLikedChannel, removeLikedChannel } from '../../sevices/store/slice/channels/likedChannelsSlice';

/**
 * Retrive channel component
 * 
 * @param {object} channel The single channel object. 
 * @returns 
 */
const Channel = ({ channel, key }) => {
    const dispatch = useDispatch();
    const currentChannel = useSelector(state => state.currentChannel.channel);
    const likedChannels = useSelector(state => state.likedChannels.liked);

    /**
     * On change channem stream
     * 
     * @param {object} current The selected channel
     */
    const onChangeChannel = (current) => {
        let changeChannel = {
            id: current.id,
            name: current.name,
            option: {
                autoplay: true,
                controls: true,
                responsive: true,
                loop: true,
                fluid: true,
                poster: current.logo,
                sources: [{
                    src: current.url,
                    type: 'application/x-mpegURL'
                }
                ]
            }
        }
        dispatch(setCurrentChannel(changeChannel));
    }

    /**
     * Check if a channel is already liked.
     * 
     * @param {ChannelObject} channel The channel to be liked
     * @returns 
     */
    const isLikedChannel = (channel) => {
        if (!likedChannels) {
            return false;
        }
        return likedChannels.filter(liked => liked.id == channel.id).length > 0 ? true : false;
    }

    /**
       * On change channem stream
       * 
       * @param {object} current The selected channel
       */
    const onLikeChannel = (channel) => {
        if (isLikedChannel(channel)) {
            dispatch(removeLikedChannel(channel.id));
        } else {
            dispatch(addLikedChannel(channel));
        }
    }

    return (
        <li key={channel.id} className="flex justify-between  py-2 px-2">
            <div className="flex min-w-0 gap-x-4">
                <img className="h-9 w-9 flex-none rounded-full bg-gray-50" src={channel.logo ? channel.logo:'/photo_icon.png'} alt="" />
                <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-4 text-gray-900">{channel.name}</p>
                    <p className="mt-0 truncate text-xs leading-5 text-gray-500">
                        {channel.categories.map((categorie) => (
                            <pan className="px-1">  {categorie} </pan>
                        ))}
                    </p>
                </div>
            </div>
            <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                <div className='sm:flex'>
                    <DarkButton onClickButton={() => onChangeChannel(channel)}>
                        <PlayIcon className="h-6 w-6 text-white-600" aria-hidden="true" />
                    </DarkButton>
                    <DarkButton onClickButton={() => onLikeChannel(channel)} current={isLikedChannel(channel)}>
                        <HeartIcon className="h-6 w-6 text-white-600" aria-hidden="true" />
                    </DarkButton>
                    {channel.id === currentChannel.id && (
                        <div className="mt-1 flex items-center gap-x-1.5">
                            <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                            </div>
                            <p className="text-xs leading-5 text-gray-500">Online</p>
                        </div>
                    )}
                </div>

            </div>
        </li>
    );
}

export default Channel;
