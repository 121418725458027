import { createSlice } from '@reduxjs/toolkit';

const allChannelsSlice = createSlice({
    name: 'allChannels',
    initialState: {
        channels: [],
    },
    reducers: {
        addChannel: (state, action) => {
            state.channels = action.payload;
        },
        updateChannel: (state, action) => {
            const { id, newData } = action.payload;
            const index = state.channels.findIndex(channel => channel.id === id);
            if (index !== -1) {
                state.channels[index] = { ...state.channels[index], ...newData };
            }
        },
        removeChannel: (state, action) => {
            const index = state.channels.findIndex(channel => channel.id === action.payload);
            if (index !== -1) {
                state.channels.splice(index, 1);
            }
        },
    },
});

export const { addChannel, updateChannel, removeChannel } = allChannelsSlice.actions;
export default allChannelsSlice.reducer;
